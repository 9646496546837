<template>
    <v-card flat :outlined="outlined" :width="width" :class="outlined ? 'pa-1' : ''">
        <strong v-if="title" class="ml-3 mb-n4 text-body-2 font-weight-bold grey--text">{{ title }}</strong>
        <v-list-item dense two-line>
            <!-- <v-list-item-icon><v-icon>mdi-map-marker</v-icon></v-list-item-icon> -->
            <v-list-item-content>
                <strong v-if="showName && address.name" class="pb-1">{{ address.name }}</strong>
                <label>{{ buildAddressPreview(address) }}</label>
                <label v-if="showName && address.contactNo" class="pt-1"><v-icon small
                        class="mr-1">mdi-cellphone-basic</v-icon>{{
                            address.contactNo
                        }}</label>
            </v-list-item-content>
            <v-list-item-action>
                <div class="flex">
                    <v-btn icon small v-if="editable && (typeof address !== 'string')" @click="$emit('edit')"><v-icon
                            small>mdi-pencil</v-icon></v-btn>
                    <v-btn icon small v-if="deletable" @click="$emit('delete')"><v-icon
                            small>mdi-delete</v-icon></v-btn>
                </div>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    props: {
        address: {
            type: [Object, String]
        },
        showName: {
            type: Boolean,
            default: () => false
        },
        width: {
            type: String,
            default: () => "100%"
        },
        editable: {
            type: Boolean,
            default: () => false
        },
        deletable: {
            type: Boolean,
            default: () => false
        },
        title: {
            type: String,
            default: () => ""
        },
        outlined: {
            type: Boolean,
            default: () => true
        },
    },
}
</script>

<style lang="scss" scoped></style>