<template>
    <div>
        <label class="font-weight-bold">Select Delivery Type</label><br>
        <v-radio-group :row="showInline" mandatory v-model="deliveryType">
            <v-radio v-for="type in deliveryTypes" :key="type.code" :label="type.label ? type.label : type"
                :value="type">
            </v-radio>
        </v-radio-group>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Object, String],
            default: () => undefined
        },
        showInline: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            deliveryTypes: this.$store.getters.vendor.delivery
        }
    },
    computed: {
        deliveryType: {
            get() {
                if (this.value) {
                    return this.value
                } else {
                    this.$emit(this.appEvents.INPUT, this.deliveryTypes[0])
                    return this.deliveryTypes[0]
                }
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue)
            }
        }
    },
}
</script>
