<template>
    <v-simple-table>
        <thead>
            <tr>
                <th v-if="!viewOnly"></th>
                <th class="text-center">Image</th>
                <th>Code</th>
                <th>Title</th>
                <th class="text-right">Price</th>
                <th class="text-right">Purchase Price</th>
                <!-- <th class="text-right">Available Stock</th>
                <th class="text-right">Reserve Stock Level</th> -->
                <th class="text-right" v-if="!viewOnly">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(variant, index) in variants" :key="index">
                <td v-if="!viewOnly">
                    <v-radio-group v-model="variant.isDefaultVariant" column>
                        <v-icon v-if="variant.isDefaultVariant">mdi-radiobox-marked</v-icon>
                        <v-radio v-else @change="$emit('defaultVariantChanged', variant)"></v-radio>
                    </v-radio-group>
                </td>
                <td class="text-center">
                    <file-upload ref="fileupload" v-model="variant.images" :value="variant.images" label=""
                        accept="image/*" :fileName="variant.code.trim()" :customAttributes="variant.variant"
                        :showClose="!variant._id" v-if="!variant._id" width="100px" height="100px">
                    </file-upload>
                    <image-card :src="variant?.images[0]?.path" width="100" height="100" v-else>
                    </image-card>
                </td>
                <td>
                    <template v-if="!variant._id">
                        <v-text-field label="Code" v-model="variant.code"></v-text-field>
                    </template>
                    <template v-else>
                        <p class="text-caption mt-n4">{{ variant.code }}</p>
                    </template>
                </td>
                <td>
                    <template v-if="!variant._id">
                        <v-text-field label="Title" v-model="variant.title"></v-text-field>
                    </template>
                    <template v-else>
                        <p class="text-h6"><a href="#" @click="navigateTo(`/app/products/view/${variant._id}`)">{{
                            variant.title }}</a><v-icon color="green"
                                v-if="viewOnly && variant.isDefaultVariant">mdi-check</v-icon></p>
                    </template>
                    <p class="text-caption mt-n4">{{ variant._id }}</p>
                </td>
                <td class="text-right">
                    <v-text-field outlined dense type="number" v-model="variant.price" label="Selling Price"
                        class="mb-n6" :readonly="variant._id" v-if="!variant._id">
                    </v-text-field>
                    <label v-else>
                        <v-icon small>mdi-currency-inr</v-icon>{{ variant.price }}
                    </label>
                </td>
                <td class="text-right">
                    <v-text-field outlined dense type="number" v-model="variant.offerprice" label="Offer Price"
                        class="mb-n6" :readonly="variant._id" v-if="!variant._id">
                    </v-text-field>
                    <label v-else>
                        <v-icon small>mdi-currency-inr</v-icon>{{ variant.offerprice }}
                    </label>
                </td>
                <!-- <td class="text-right">
                    <v-text-field outlined dense type="number" v-model="variant.stock" label="Current Stock"
                        class="mb-n6" :readonly="variant._id" v-if="!variant._id">
                    </v-text-field>
                    <label v-else>
                        <v-icon small>mdi-currency-inr</v-icon>{{ variant.stock }}
                    </label>
                </td>
                <td class="text-right">
                    <v-text-field outlined dense type="number" v-model="variant.reserveStock"
                        label="Reserve Stock Level" class="mb-n6" :readonly="variant._id" v-if="!variant._id">
                    </v-text-field>
                    <label v-else>
                        <v-icon small>mdi-currency-inr</v-icon>{{ variant.reserveStock }}
                    </label>
                </td> -->
                <td class="text-right" v-if="!viewOnly">
                    <v-btn title="Link Product" small @click="$emit('link', index)" icon v-if="!variant._id">
                        <v-icon>mdi-link-box-variant</v-icon>
                    </v-btn>
                    <v-btn small @click="$emit('edit', variant._id)" icon v-if="variant._id">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small @click="$emit('delete', { index: index, _id: variant._id })" icon>
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        viewOnly: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        variants: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
}
</script>

<style lang="scss" scoped></style>