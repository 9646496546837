<template>
    <div>
        <slot name="activator" :toggleDialog="toggleDialog">
            <v-btn :block="block" @click="toggleDialog" :style="theme" v-bind="$attrs">{{ label }}</v-btn>
        </slot>
        <app-dialog :show="showDialog" :title="title" @close="toggleDialog()" :width="width">
            <slot :toggleDialog="toggleDialog"></slot>
        </app-dialog>
    </div>
</template>

<script>
import AppDialog from './AppDialog.vue'
export default {
    components: {
        AppDialog
    },
    props: {
        label: {
            type: String,
            default: () => "Activate"
        },
        block: {
            type: Boolean,
            default: () => false
        },
        title: {
            type: String,
            default: () => ""
        },
        width: {
            type: [Number, String],
            default: () => "40%"
        }
    },
    data() {
        return {
            showDialog: false
        }
    },
    methods: {
        toggleDialog() {
            this.showDialog = !this.showDialog
        }
    },
}
</script>

<style lang="scss" scoped></style>