<template>
    <div ref="orderDetails" style="padding: 2% !important">
        <h2 v-if="show" data-html2canvas-ignore="true" class="text-center">Invoice
            Preview</h2>
        <v-card flat style="background-color:white" v-if="show">
            <v-card-text>
                <!-- Invoice Header -->
                <v-row v-if="show" align="center">
                    <v-col cols="8">
                        <h2 class="text-color">INVOICE</h2>
                    </v-col>
                    <v-col cols="4" class="top-padding">
                        <v-img v-if="$store.getters.vendor.logo" class="logo" width="10%"
                            :src="host + $store.getters.vendor.logo.path" />
                        <v-icon v-else x-large class="mr-2" :style="themeInverted">mdi-handshake</v-icon>
                        <label :style="themeInverted" class="title">{{ storeName }}</label><br />
                        <label class="font-theme" v-if="$store.getters.vendor.mobileNo">{{
                            $store.getters.vendor.mobileNo }}<br /></label>
                        <label class="font-theme" v-if="$store.getters.vendor.email">{{ $store.getters.vendor.email
                            }}<br /></label>
                        <label class="font-theme" v-if="$store.getters.vendor.address">{{ $store.getters.vendor.address
                            }}<br /></label>
                    </v-col>
                </v-row>
                <hr class="text-color" style="margin-top: 1% !important">
            </v-card-text>
        </v-card>
        <v-card flat v-if="show">
            <v-card-text>
                <v-row>
                    <v-col cols="8">
                        <label>To :<br><span :style="themeInverted"> {{ order.customer.displayName }}
                            </span></label><br>
                        <label class="font-theme">{{ order.customer.value }}</label><br>
                        <label class="font-theme" v-if="order.delivery.type">{{ order.delivery.type.label }}</label><br>
                    </v-col>
                    <v-col cols="4">
                        <label class="font-theme">Invoice No # : {{ order.orderNo }}</label><br>
                        <label class="font-theme">Order date : {{ order.date | dateTimeFormat }}</label><br>
                        <label class="font-theme">Delivery Date : {{ order.delivery.date }}</label><br>
                        <label class="font-theme">Status : <b>{{ order.currentStatus }}</b></label><br>
                        <label class="font-theme">Payment Status : <b :class="order?.pStatus?.toUpperCase()">{{
                            order.pStatus }}</b></label><br>
                    </v-col>
                    <v-col cols="12">
                        <strong :style="themeInverted" v-if="order.delivery.address">Delivery Address:</strong><br>
                        <strong class="font-theme" v-if="order.delivery.address.name">
                            {{ order.delivery.address.name }}</strong><br>
                        <label class="font-theme" v-if="order.delivery.address.contactNo">
                            Contact No: {{ order.delivery.address.contactNo }}</label><br>
                        <div class="font-theme" v-if="order.delivery.address" style="width: 200px!important">
                            {{ buildAddressPreview(order.delivery.address) }}</div><br>
                    </v-col>
                </v-row>
                <hr class="text-color">
            </v-card-text>
        </v-card>
        <v-card flat v-if="show">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <p class="text-h6">Items</p>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead :style="theme">
                                    <th class="text-left" style="padding: 1% !important">Code</th>
                                    <th class="text-left" style="padding: 1% !important">Product</th>
                                    <th class="text-right" style="padding: 1% !important">Quantity</th>
                                    <th class="text-right" style="padding: 1% !important">Unit Price(₹)</th>
                                    <th class="text-right" style="padding: 1% !important">Amount(₹)</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in order.items" :key="index">
                                        <td class="text-left font-theme">{{ item.code }}</td>
                                        <td class="text-left font-theme">{{ item.title }}</td>
                                        <td class="text-right font-theme">{{ item.quantity }}</td>
                                        <td class="text-right font-theme">₹{{ item.price }}</td>
                                        <td class="text-right font-theme">₹{{ item.price * item.quantity |
                                            amountFormat
                                            }}</td>
                                    </tr>
                                    <tr class="border-top">
                                        <td colspan="4" class="text-right font-theme font-weight-bold">Subtotal</td>
                                        <td class="text-right">₹{{ order.subTotal | amountFormat }}</td>
                                    </tr>
                                    <tr v-if="order.discount.code">
                                        <td colspan="4" class="text-right font-theme font-weight-bold">Coupon</td>
                                        <td class="text-right">₹{{ order.discount.code }}</td>
                                    </tr>
                                    <tr v-if="order.discount.amount > 0">
                                        <td colspan="4" class="text-right font-theme font-weight-bold">Discount</td>
                                        <td class="text-right">₹{{ order.discount.amount | amountFormat }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right font-theme font-weight-bold">Tax</td>
                                        <td class="text-right">₹{{ order.taxAmount | amountFormat }}</td>
                                    </tr>
                                    <tr v-if="order.delivery.charge > 0">
                                        <td colspan="4" class="text-right font-theme font-weight-bold">Delivery</td>
                                        <td class="text-right">
                                            ₹{{ order.delivery.charge | amountFormat }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right">
                                            <label class="font-weight-bold">Total</label>
                                        </td>
                                        <td class="text-right">
                                            ₹{{ order.total | amountFormat }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col class="mt-4 font-theme">
                        <strong>Amount In Words:</strong>
                        <p>{{ convertToWords(order.total) }}</p><br><br>
                        <label>Thank you for your business</label><br><br>
                    </v-col>
                </v-row>
                <footer>
                    <hr>
                    <p>*This is a computer-generated invoice and does not require a signature
                    </p>
                </footer>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import RowItem from '@/components/RowItem'
import html2pdf from 'html2pdf.js'
export default {
    components: {
        RowItem
    },
    props: {
        order: {
            type: Object
        },
        show: {
            type: Boolean
        }
    },
    methods: {
        async exportToPDF() {
            await html2pdf(this.$refs.orderDetails, {
                filename: `order-${this.order.orderNo}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 192 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                backgroundColor: '#FFFFFF', // Ensure white background
                useCORS: true, // Fix CORS issues for images
            })
            this.$emit(this.appEvents.UPDATE)
        }
    }
}
</script>

<style scoped>
.border-top {
    border-top: 1px solid black;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.font-theme {
    font-size: 12px !important;
    font-family: Arial, Helvetica, sans-serif;
}

.title {
    font-size: 16px !important;
}

.total-font {
    font-size: 18px !important;
}

.text-color {
    color: #D3D3D3 !important;
}

.top-padding {
    padding-top: 3%;
}

.logo {
    vertical-align: middle
}

.v-card {
    background-color: white !important;
    box-shadow: none !important;
}

.PAID {
    color: green;
}

.PARTIAL {
    color: orange;
}

.CREDIT,
.COD {
    color: red;
}

@media print {
    .v-card {
        border: none !important;
        background: white !important;
    }
}
</style>
