<template>
    <v-dialog v-model="show" :width="width" :fullscreen="isMobile" scrollable max-height="600px" :title="title"
        persistent>
        <v-card>
            <slot name="title">
                <v-toolbar flat class="dialog">
                    <label class="text-body-1 font-weight-bold">{{ title }}</label>
                    <v-spacer></v-spacer>
                    <v-btn @click="$emit(appEvents.CLOSE)" icon text>
                        <v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
            </slot>
            <v-card-text>
                <!-- @slot Default slot where you can provide customized content -->
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
/** 
 * This is application dialog box. 
 * This is global component can be used with `<app-dialog></app-dialog>`
 **/
export default {
    props: {
        /**
         * This flag is use to control the visibility of the dialog box
         */
        show: {
            type: Boolean,
            required: true,
            default: () => false
        },
        /**
         * Provide title to dialog box
         */
        title: {
            type: String,
            required: false
        },
        width: {
            type: String,
            default: () => "600"
        }

    }
};
</script>
<style scoped>
.dialog {
    flex: none !important;
}
</style>