<template>
    <v-list-item @click="$router.push('/app/customers/view/' + id)">
        <v-list-item-avatar :style="theme" class="align-avatar">
            {{ name[0] }}
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ name }}</v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ mobileNo }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <action-button key="Edit" icon="mdi-pencil" :show="isAllowed('editCustomer')"
                            @click="$router.push('/app/customers/' + id)"></action-button>
                    </v-list-item>
                    <v-list-item>
                        <action-button key="Delete" icon="mdi-delete" :show="isAllowed('deleteCustomer')"
                            @click="$emit('delete', id)"></action-button>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    props: ["id", "name", "email", "mobileNo", "icons", "slug", "roles"],
};
</script>

<style scoped>
.align-avatar {
    margin-left: 5px !important;
    margin-top: 10px !important;
}

.align-card-subtitle {
    margin-top: 20px;
}

.align-card-title {
    margin-top: 5px;
    margin-left: -10px
}
</style>
