<template>
    <v-autocomplete v-model="item" :items="filterData" :search-input.sync="searchText" :item-value="uniqueKey"
        :no-filter="true" :label="label" return-object v-bind="$attrs">
        <template v-slot:item="{ item }">
            <slot :item="item">
                <v-list-item-content>
                    <v-list-item-title v-text="item[title]"></v-list-item-title>
                    <v-list-item-subtitle v-text="item[subtitle]"></v-list-item-subtitle>
                </v-list-item-content>
            </slot>
        </template>
        <template v-slot:selection="{ item }">
            <slot name="selection" :item="item">
                <label>{{ item[title] }}</label>
            </slot>
        </template>
        <template v-if="showAddButton" v-slot:append-item>
            <slot name="addButton">
                <v-btn @click="$emit('addButtonClicked')" class=" ma-2" :style="theme">
                    <v-icon>mdi-plus</v-icon>Add New
                </v-btn>
            </slot>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
        },
        items: {
            type: Array,
            default: () => []
        },
        value: {
            type: [Object, String],
            default: () => { }
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
        },
        showAddNewButton: {
            type: Boolean,
            default: () => false
        },
        uniqueKey: {
            type: String,
            default: () => "_id"
        }
    },
    data() {
        return {
            searchText: ''
        }
    },
    computed: {
        item: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue)
            }
        },
        filterData() {
            if (this.searchText) {
                return this.search(this.items, this.searchText)
            } else {
                return this.items
            }
        },
        showAddButton() {
            if (this.showAddNewButton)
                return this.showAddNewButton
            else
                return false
        }
    },
    methods: {
        search(list, searchText) {
            searchText = searchText.toLowerCase()
            if (this.subtitle != undefined)
                return list.filter(rec => rec[this.title]?.toLowerCase().includes(searchText) || rec[this.subtitle]?.toLowerCase().includes(searchText))
            else
                return list.filter(rec => rec[this.title]?.toLowerCase().includes(searchText))
        },
    },
}
</script>

<style lang="scss" scoped></style>
