<template>
    <v-row>
        <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3">
            <v-card class="display-block" elevation="10">
                <v-card-title>
                    <v-toolbar flat>
                        <v-toolbar-title>
                            My Details
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-icon @click="readonly = !readonly" v-if="readonly">mdi-pencil</v-icon>
                        <v-icon @click="readonly = !readonly" v-if="!readonly">mdi-close</v-icon>
                    </v-toolbar>
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="msg" :type="msgType">
                        <ul v-for="item in msg" :key="item.errors">
                            <li>{{ item }}</li>
                        </ul>
                    </v-alert>
                    <v-form ref="form" lazy-validation>
                        <v-layout wrap>
                            <v-flex sm4 xs12>
                                <row-item label="Firstname" :value="customer.firstName" v-if="readonly"></row-item>
                                <v-text-field v-if="!readonly" label="FirstName*" class="mx-3" :rules="firstNameRules"
                                    v-model="customer.firstName" required></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <row-item label="Middlename" :value="customer.middleName" v-if="readonly"></row-item>
                                <v-text-field v-if="!readonly" label="MiddleName" class="mx-3"
                                    v-model="customer.middleName"></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <row-item label="Lastname" :value="customer.lastName" v-if="readonly"></row-item>
                                <v-text-field v-if="!readonly" label="LastName*" class="mx-3" :rules="lastNameRules"
                                    v-model="customer.lastName" required></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <row-item label="Mobile No" :value="customer.mobileNo" v-if="readonly"></row-item>
                                <v-text-field v-if="!readonly" label="Mobile No*" class="mx-3" :rules="mobileNoRules"
                                    v-model="customer.mobileNo" required></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <row-item label="Email" :value="customer.email"
                                    v-if="readonly || !editEmail"></row-item>
                                <v-text-field v-if="!readonly && editEmail" label="E-Mail" class="mx-3"
                                    :rules="emailRules" v-model="customer.email"></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12 v-if="$store.getters.vendor.enableRewards">
                                <row-item label="Reward Points" :value="reward.totalRewardPoint"
                                    v-if="readonly"></row-item>
                            </v-flex>
                            <v-flex sm4 xs12 v-if="customer.referralCode && $store.getters.vendor.enableRewards">
                                <row-item label="Referral Code" :value="customer.referralCode"
                                    v-if="readonly"></row-item>
                            </v-flex>
                            <v-flex xs12>
                                <row-item label="Addresses">
                                    <address-list v-model="customer.addresses" :deletable="!readonly"
                                        :editable="!readonly"></address-list>
                                </row-item>
                            </v-flex>
                            <v-flex text-right xs12>
                                <v-btn v-if="!readonly" class="mr-4" :style="theme" @click="submit()">Save</v-btn>
                            </v-flex>
                            <v-flex my-8 v-if="showTransaction">
                                <reward-logs :rewardLogs="reward.rewardLogs"
                                    :totalRewardPoint="reward.totalRewardPoint"></reward-logs>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <div class="mt-3 text-right">
                            <v-btn class="ma-2"
                                v-if="!showTransaction && readonly && $store.getters.vendor.enableRewards"
                                :style="theme" @click="showTransaction = !showTransaction">Reward history</v-btn>
                            <v-btn class="ma-2" :style="theme" @click="show = !show">Change Password</v-btn>
                            <v-btn v-if="customer.referralCode && $store.getters.vendor.enableRewards"
                                class="whatsapp-btn ma-2" title="Share on WhatsApp" :href="urlEncodedMsg()"
                                target="_blank"><v-icon class="mr-2">mdi-whatsapp</v-icon>Refer</v-btn>
                        </div>
                        <app-dialog :show="show" @close="show = !show" width="400px">
                            <reset-user-password @update="show = !show"></reset-user-password>
                        </app-dialog>
                        <v-alert v-if="msg" :type="msgType">
                            <ul v-for="item in msg" :key="item.errors">
                                <li>{{ item }}</li>
                            </ul>
                        </v-alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import appConstants from "@/utils/appConstants";
import RewardLogs from '@/components/RewardLogs'
import AddressList from '../../components/AddressList.vue';
export default {
    components: {
        RewardLogs,
        AddressList,
    },
    data() {
        return {
            customer: {
                firstName: "",
                middleName: "",
                lastName: "",
                mobileNo: "",
                email: ""
            },
            items: [],
            show: false,
            readonly: true,
            editEmail: true,
            reward: {
                totalRewardPoint: 0,
                rewardLogs: []
            },
            showTransaction: false
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id;
            this.customer = await this.getItem(
                appConstants.CUSTOMERS_API + "/" + this.id
            );
            let rewards = await this.getItem(appConstants.REWARD_API + "/user/" + this.id)
            if (rewards.length > 0)
                this.reward = rewards[0]
            if (this.customer.login == 'GOOGLE')
                this.editEmail = false
        },
        async submit() {
            if (this.$refs.form.validate()) {
                try {
                    this.customer = await this.putItem(
                        appConstants.CUSTOMERS_API + "/" + this.customer._id,
                        this.customer
                    );
                    var localUser = this.$store.getters.user;
                    localUser.displayName = this.customer.firstName + (this.customer.middleName ? " " + this.customer.middleName : "") + " " + this.customer.lastName;
                    localUser.mobileNo = this.customer.mobileNo;
                    localUser.email = this.customer.email;
                    this.$store.dispatch("actionUpdateUser", localUser);
                    this.readonly = true
                } catch (error) {
                    this.handleError(error);
                    this.msg = error.response.data.errors;
                }
            }
        },
        urlEncodedMsg() {
            let url = window.location.href.substring(0, window.location.href.indexOf('/editAccount')) + "\n"
            let msg = "Hi there,\nCheckout *" + this.$store.getters.vendor.businessName + "* store at\n" + url + "Use referral code " + this.customer.referralCode + " for additional rewards\n"
            return encodeURI("https://wa.me/?text=" + msg)
        }
    }
};
</script>
