<template>
    <v-card flat class="text-center">
        <div class="text-right mt-n2 mr-n2" v-if="showClose">
            <v-btn color="red" text x-small @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <span class="text-center">
            <template v-if="src">
                <img :src="imagePath(src)" :width="width" :height="height" v-if="src.startsWith('/')" contain />
                <img :src="src" :width="width" :height="height" v-else contain />
            </template>
            <template v-else>
                <img :src="defaultProductImage" :width="width" :height="height" contain />
            </template>
        </span>
    </v-card>
</template>

<script>
export default {
    props: {
        src: {
            type: String
        },
        showClose: {
            type: Boolean,
            default: () => false
        },
        width: {
            type: [Number, String],
            default: () => "100%"
        },
        height: {
            type: [Number, String],
            default: () => "auto"
        }
    },
}
</script>

<style scoped></style>
