<template>
    <div>
        <alert-message v-if="msg" :error="msg"></alert-message>
        <v-row align="center">
            <v-col cols="12" :sm="customField.type !== appConstants.CUSTOM_FIELD.TYPE.PREDEFINED ? '4' : '6'">
                <v-select label="Type*" v-model="customField.type" item-text="label"
                    :items="customFieldTypes"></v-select>
            </v-col>
            <v-col cols="12" :sm="customField.type !== appConstants.CUSTOM_FIELD.TYPE.PREDEFINED ? '4' : '6'">
                <v-text-field label="Label*" v-model="customField.label" clearable></v-text-field>
                {{ field }}
            </v-col>
            <v-col cols="12" sm="4"
                v-if="customField.type == appConstants.CUSTOM_FIELD.TYPE.TEXT || customField.type == appConstants.CUSTOM_FIELD.TYPE.NUMBER">
                <v-text-field :type="customField.type !== appConstants.CUSTOM_FIELD.TYPE.NUMBER ? 'number' : 'text'"
                    label="Default Value" v-model="customField.defaultValue"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="customField.type == appConstants.CUSTOM_FIELD.TYPE.BOOLEAN">
                <v-radio-group label="Default Value" column v-model="customField.defaultValue">
                    <v-radio label="True" :value="true"></v-radio>
                    <v-radio label="False" :value="false"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" v-if="customField.type == appConstants.CUSTOM_FIELD.TYPE.PREDEFINED">
                <v-text-field placeholder="Add Vale" v-model="newValue" @keyup.enter.prevent="addValue()"
                    @blur="addValue()">
                    <template v-slot:append>
                        <v-btn small @click="addValue()"><v-icon>mdi-plus</v-icon></v-btn>
                    </template>
                </v-text-field>
                <template>
                    <v-chip v-for="(v, index) in customField.values" close :key="v" class="mr-1"
                        @click:close="removeValue(index)">{{ v }}</v-chip>
                </template>
            </v-col>
            <v-col cols="12" sm="6" v-if="customField.type == appConstants.CUSTOM_FIELD.TYPE.PREDEFINED">
                <v-select label="Default Value" v-model="customField.defaultValue"
                    :items="customField.values"></v-select>
            </v-col>
            <v-col cols="12" class="text-right">
                <alert-message v-if="msg" class="text-left" :error="msg"></alert-message>
                <v-btn :style="theme" class="mr-2" :loading="addingField" @click="submit()">Submit</v-btn>
                <v-btn :style="themeInverted" outlined @click="$emit('cancel')">Cancel</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import appConstants from '../../../utils/appConstants';

export default {
    props: {
        item: {
            type: Object,
        },
    },
    mounted() {
        this.customField = this.item ? this.item : this.defaultCustomField()
    },
    data() {
        return {
            customField: this.defaultCustomField(),
            customFieldTypes: Object.values(appConstants.CUSTOM_FIELD.TYPE),
            newValue: undefined,
            appConstants: appConstants,
            addingField: false
        }
    },
    computed: {
        field() {
            this.customField.field = this.customField.label.toLowerCase().split(' ').join('-');
            return this.customField.field
        }
    },
    methods: {
        defaultCustomField() {
            return {
                label: "",
                field: "",
                defaultValue: "",
                type: "text",
                values: [],
                refColor: "",
                vendorId: this.$store.getters.vendor._id
            }
        },
        addValue() {
            if (this.newValue) {
                this.customField.values.push(this.newValue)
                this.newValue = undefined
            }
        },
        removeValue(index) {
            this.customField.values.splice(index, 1)
        },
        async submit() {
            try {
                this.addingField = true
                let savedValue = undefined
                if (!this.customField.defaultValue) {
                    delete this.customField.defaultValue
                }
                if (this.customField?._id) {
                    savedValue = (await this.putItem(`${appConstants.CUSTOM_FIELDS_API}/${this.customField._id}`, this.customField))
                } else {
                    savedValue = (await this.postItem(appConstants.CUSTOM_FIELDS_API, this.customField))
                }
                this.customField = this.defaultCustomField()
                this.$emit('submit', savedValue)
            } catch (error) {
                this.handleError(error)
            } finally {
                this.addingField = false
            }
        }
    },
}
</script>

<style lang="scss" scoped></style>