<template>
    <v-list-item @click="navigateTo(`/app/inventory/view/${productInventory._id}`)">
        <v-list-item-content>
            <v-list-item-title>{{ productInventory.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ productInventory.code }}</v-list-item-subtitle>
            <v-list-item-subtitle>Reserve Stock Level: <label class="text-body-1 font-weight-bold">{{
                productInventory.reserveStock
                    }}</label></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <div class="text-center mr-4">
                <label class="text-h6 font-weight-bold"
                    :style="`color: ${productInventory.stock < productInventory.reserveStock ? 'red' : 'green'}`">{{
                        productInventory.stock }}</label><br>
                <label class="text-caption">Current Stock</label>
            </div>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    props: ["productInventory", "icons"],
};
</script>
