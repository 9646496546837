<template>
    <app-dialog title="Address Details" :show="showDialog" @close="toggleDialog(true)">
        <v-card outlined class="pa-4">
            <v-form ref="addressForm">
                <v-text-field v-model="addressObj.pincode" label="Pincode" type="text"
                    :rules="[rules.required, rules.pincode]" maxlength="6" outlined dense @blur="fetchPostalInformation"
                    @click:clear="resetComponent()" placeholder="Enter pincode"></v-text-field>
                <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                <v-alert v-else-if="error" type="error" dense outlined>{{ error }}</v-alert>
                <p class="text-body-2 font-weight-bold" v-if="postalInformation.length > 0">Select Area</p>
                <v-radio-group v-if="postalInformation.length > 0" v-model="selectedPostalRecord" row class="mt-3">
                    <v-radio v-for="(info, index) in postalInformation" :key="index"
                        @click="postalAddressToAddressMapping($event)"
                        :label="`${info.Name}, ${info.Block}, ${info.District}, ${info.State}`" :value="info"></v-radio>
                </v-radio-group>
                <div v-if="showAddressInputFields">
                    <v-card-title>Enter Address</v-card-title>
                    <!-- Address Input Fields -->
                    <v-text-field v-model="addressObj.name" label="Name" outlined dense></v-text-field>
                    <v-text-field v-model="addressObj.house" label="Flat/House No/Residence/Shop No/Residence Name"
                        :rules="[rules.required]" outlined dense></v-text-field>
                    <v-text-field v-model="addressObj.societyName" label="Building/Society Name/Area Name"
                        :rules="[rules.required]" outlined dense></v-text-field>
                    <v-text-field v-model="addressObj.landmark" label="Landmark" outlined dense></v-text-field>
                    <v-text-field v-model="addressObj.contactNo" label="Contact No." outlined dense></v-text-field>
                    <!-- Address Preview -->
                    <v-card-subtitle v-if="selectedPostalRecord">
                        <strong>Name: </strong>{{ addressObj.name }}<br>
                        <strong>Address:</strong>
                        {{ addressPreview }}<br>
                        <strong>Contact No: </strong>{{ addressObj.contactNo }}<br>
                    </v-card-subtitle>
                </div>
                <div class="text-right">
                    <v-btn :style="theme" small class="mr-1" @click="save()">Save</v-btn>
                    <v-btn :style="themeInverted" outlined small @click="$emit('cancel')">Cancel</v-btn>
                </div>
            </v-form>
        </v-card>
    </app-dialog>
</template>

<script>
import axios from "axios";
import AppDialog from './AppDialog.vue';

export default {
    components: { AppDialog },
    name: "AddressComponent",
    props: {
        address: {
            type: [Object, String]
        }
    },
    data() {
        return {
            postalInformation: [], // List of postal records fetched
            selectedPostalRecord: undefined, // Selected postal record
            addressObj: this.defaultAddressObject(),
            loading: false,
            error: null,
            rules: {
                required: (v) => !!v || "This field is required",
                pincode: (v) => /^\d{6}$/.test(v) || "Pincode must be 6 digits",
            },
            showDialog: false
        };
    },
    mounted() {
        this.updateAddress(this.address)
    },
    watch: {
        address(newValue, oldValue) {
            this.updateAddress(newValue)
        }
    },
    computed: {
        // Build the complete address preview
        showAddressInputFields() {
            return (this.selectedPostalRecord || this.address) ? true : false
        },
        addressPreview() {
            this.postalAddressToAddressMapping(this.selectedPostalRecord)
            return this.buildAddressPreview(this.addressObj)
        },
    },
    methods: {
        // Fetch postal information based on pincode
        async fetchPostalInformation() {
            if (!this.addressObj.pincode || !/^\d{6}$/.test(this.addressObj.pincode)) {
                this.error = "Please enter a valid 6-digit pincode.";
                return;
            }
            this.loading = true;
            this.error = null;
            try {
                const response = await axios.get(`https://api.postalpincode.in/pincode/${this.addressObj.pincode}`);
                const data = response.data[0];
                if (data.Status === "Success") {
                    this.postalInformation = data.PostOffice || [];
                    this.selectedPostalRecord = this.postalInformation.length === 1 ? this.postalInformation[0] : null; // Auto-select if only one record exists
                } else {
                    this.error = "No postal information found for this pincode.";
                    this.postalInformation = [];
                    this.selectedPostalRecord = null;
                }
            } catch (err) {
                this.error = "Failed to fetch postal information. Please try again.";
            } finally {
                this.loading = false;
            }
        },
        updateAddress(newValue) {
            this.addressObj = newValue ? newValue : this.defaultAddressObject()
        },
        defaultAddressObject() {
            return {
                house: "",
                societyName: "",
                landmark: "",
                area: "",
                town: "",
                district: "",
                state: "",
                pincode: "",
                name: "",
                contactNo: ""
            }
        },
        postalAddressToAddressMapping(postalAddress) {
            this.addressObj['area'] = postalAddress.Name
            this.addressObj['town'] = postalAddress.Block
            this.addressObj['district'] = postalAddress.District
            this.addressObj['state'] = postalAddress.State
            this.addressObj['pincode'] = postalAddress.Pincode
        },
        save() {
            if (this.$refs.addressForm.validate()) {
                this.$emit('success', { ...this.addressObj });
                this.resetComponent()
            }
        },
        resetComponent() {
            this.addressObj = this.defaultAddressObject()
            this.selectedPostalRecord = undefined
            this.error = null;
            this.loading = false;
            this.postalInformation = []
        },
        toggleDialog(reset = false) {
            this.showDialog = !this.showDialog
            if (reset)
                this.resetComponent()
        }
    },
};
</script>

<style scoped>
.v-card {
    max-width: 600px;
    margin: auto;
}
</style>