<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <p class="text-h6">Add Variants</p>
                </v-col>
                <v-col cols="12" sm="6">
                    <!--@addButtonClicked='showDialog' -->
                    <auto-complete :disabled="!!product._id" v-model="selectedField" :showAddNewButton="true"
                        :items="customFields" title="label" label="Select Option" uniqueKey="_id"
                        @addButtonClicked="toggleCustomFieldDialog()">
                    </auto-complete>
                </v-col>
                <v-col cols="12" sm="6">
                    <template v-if="selectedField">
                        <v-chip v-for="(v, index) in selectedField.values" close :key="v" class="mr-1"
                            @click:close="removeValue(index)">{{ v }}</v-chip>
                    </template>
                    <v-text-field placeholder="Add Vale" v-model="newValue"
                        @keyup.enter.prevent="!!selectedField ? addValue() : ''" @blur="addValue()">
                        <template v-slot:append>
                            <v-btn small @click="addValue()"
                                :disabled="!selectedField"><v-icon>mdi-plus</v-icon></v-btn>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-btn :disabled="!selectedField" block @click="addConfig()">{{ product._id ? 'Update' : 'Add'
                        }}</v-btn>
                </v-col>
            </v-row>

            <p class="text-h6 mt-4">Variants</p>
            <v-list v-if="variantConfigs.length > 0">
                <v-list-item class="pa-2" v-for="(config, index) in variantConfigs" :key="config._id">
                    <v-list-item-content>
                        <v-list-item-title>{{ config.label }}</v-list-item-title>
                        <div class="flex">
                            <v-chip v-for="val in config.values" label :key="val" class="ma-1">{{ val }}</v-chip>
                        </div>
                        <!-- <v-list-item-subtitle>{{ config.values.join(', ') }}</v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action>
                        <div class="d-flex">
                            <v-icon @click="editConfig(index, config)">mdi-pencil</v-icon>
                            <v-icon @click="removeConfig(index)" v-if="!product._id">mdi-delete</v-icon>
                        </div>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <!--:excludes="{ size: ['small', 'large'], color: ['Yello'] }"-->
        </v-card-text>
        <app-dialog :show="showAddCustomFieldDialog" @close="toggleCustomFieldDialog()" title="Modify Custom Field">
            <add-custom-field-form @submit="handleNewCustomField($event)"
                @cancel="toggleCustomFieldDialog()"></add-custom-field-form>
        </app-dialog>
    </v-card>
</template>

<script>
import AppDialogWithBtn from '../../../components/AppDialogWithBtn.vue'
import AutoComplete from '../../../components/AutoComplete.vue'
import ImageCard from '../../../components/ImageCard.vue'
import appConstants from '../../../utils/appConstants'
import AddCustomFieldForm from '../customField/AddCustomFieldForm.vue'
import CustomFields from "../CustomFields.vue"
import AvailableVariants from './AvailableVariants.vue'

export default {
    components: { CustomFields, AutoComplete, ImageCard, AvailableVariants, AppDialogWithBtn, AddCustomFieldForm },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        product: {
            type: Object
        },
        images: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            customFields: [],
            selectedField: undefined,
            newValue: undefined,
            editIndex: undefined,
            showAddCustomFieldDialog: false
        }
    },
    computed: {
        variantConfigs: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    mounted() {
        //this.variantAvailable.images = this.images
        this.loadCustomFields();
    },
    methods: {
        async loadCustomFields() {
            this.customFields = await this.getItem(appConstants.CUSTOM_FIELDS_API)
        },
        removeValue(index) {
            this.selectedField.values.splice(index, 1)
        },
        addValue() {
            if (this.newValue) {
                this.selectedField.values.push(this.newValue)
                this.newValue = undefined
            }
        },
        addConfig() {
            if (this.editIndex) {
                this.variantConfigs.splice(this.editIndex, 0, this.selectedField)
                this.editIndex = undefined
            } else {
                this.variantConfigs.push(this.selectedField)
            }
            this.selectedField = undefined
            this.$emit('input', this.variantConfigs)
            //this.variantAvailable = this.computeVariants()
        },
        editConfig(index, editConfig) {
            this.editIndex = index
            this.selectedField = editConfig
            this.variantConfigs.splice(index, 1)
        },
        cancelEdit() {
            this.editIndex = undefined
            this.selectedField = undefined
        },
        removeConfig(index) {
            this.variantConfigs.splice(index, 1)
        },
        toggleCustomFieldDialog() {
            this.showAddCustomFieldDialog = !this.showAddCustomFieldDialog
        },
        handleNewCustomField(newCustomField) {
            this.selectedField = newCustomField
            this.loadCustomFields()
            this.toggleCustomFieldDialog()
        }
    },
}
</script>

<style lang="scss" scoped></style>