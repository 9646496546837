<template>
  <v-card>
    <v-card-title>
      <!-- @slot This slot provided title and close button by default. -->
      <slot name="titlebar">
        <v-layout>
          <v-flex>{{ title }}</v-flex>
          <v-flex text-right>
            <v-btn fab small class="primary-inverted" @click="openDialog(0)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </slot>
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" solo clearable></v-text-field>
      <v-data-table :headers="headers" :items="items" :search="search">
        <template v-slot:item="{ item }">
          <tr>
            <!-- @slot Use this slot to override default style of display each item-->
            <slot name="item" :item="item">
              <template v-for="(col, index) in headers" v-if="isTabletAndUp">
                <td :key="index" v-if="col.value != 'action'">
                  <!-- @slot Use this slot to override the default style of each column -->
                  <slot :name="col.value" :item="item">{{ getNested(item, col.value) }}</slot>
                </td>
                <td :key="index" v-else-if="col.value == 'action' && enableAction">
                  <!-- @slot Use this slot to override display & functionality of action buttons -->
                  <slot name="actions">
                    <v-flex text-right>
                      <v-btn icon @click="openDialog(item._id)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="deleteItem(item._id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </slot>
                </td>
              </template>
              <v-card flat v-if="isMobile">
                <template v-for="(col, index) in headers">
                  <v-layout :key="index" v-if="col.value != 'action'" class="my-2">
                    <v-flex xs6><b>{{ col.text }}</b></v-flex>
                    <v-flex xs6>
                      <slot :name="col.value" :item="item">
                        <v-flex>{{ getNested(item, col.value) }}</v-flex>
                      </slot>
                    </v-flex>
                  </v-layout>
                  <v-flex :key="index" v-else-if="col.value == 'action' && enableAction">
                    <slot name="actions">
                      <v-flex text-right>
                        <v-btn icon @click="openDialog(item._id)">
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="deleteItem(item._id)">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </v-flex>
                    </slot>
                  </v-flex>
                </template>
              </v-card>
              <v-divider></v-divider>
            </slot>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <!-- @slot Override this slot when you want to change appearance other than dialog box -->
    <slot name="detailsForm">
      <app-dialog :show="show" @close="closeDialog()" :title="myTitle">
        <dynamic-form :fields="fields" v-model="item" :mode="mode" @close="closeDialog()" @submit="onSubmit()">
        </dynamic-form>
      </app-dialog>
    </slot>
  </v-card>
</template>

<script>
import axios from "axios";
/**
 * This component  provide uniform structure of title, searchbar & content in tabular form with CURD functionality
 * This component is responsive. Render the items in table format to tablet and above devices and change its layout
 * for mobile devices
 * You can override the appearance by overriding respective slot.
 */
export default {
  props: {
    /**
     *  Title of the page
     */
    title: {
      type: String,
      default: () => ""
    },
    /**
     * List of table column headers
     */
    headers: {
      type: Array,
      default: () => []
    },
    /**
     * List of items to be render in table body
     */
    items: {
      type: Array,
      default: []
    },
    /**
     * Flag to enable or disable action column
     */
    enableAction: {
      type: Boolean,
      default: () => true
    },
    /**
     * List of Form fields
     */
    formFields: {
      type: Array,
      default: () => []
    },
    /**
     * List of form fields which should be readonly in EDIT mode
     */
    formReadOnlyFields: {
      type: Array,
      default: () => []
    },
    /**
     * Api endpoint to read source object from
     */
    sourceApi: {
      type: String
    },
    /**
     * router endpoint where screen should be redirected
     */
    redirectApi: {
      type: String
    }
  },
  computed: {
    showDialog: {
      get() {
        if (this.show) {
          this.initItem();
          return true;
        }
        return false;
      },
      set(newValue) {
        this.show = newValue;
      }
    }
  },
  data() {
    return {
      search: null,
      //showDialog: false,
      show: false,
      itemId: 0,
      item: null,
      fields: [],
      mode: "ADD",
      myTitle: this.title
    };
  },
  created() {
    if (this.enableAction) {
      if (this.headers.find(header => header.value == "action") == null) {
        this.headers.push({ text: "Actions", value: "action", align: "right" });
      }
    }
  },
  methods: {
    resetFormFields() {
      this.fields = JSON.parse(JSON.stringify(this.formFields));
    },
    async initItem() {
      this.resetFormFields();
      if (this.itemId != 0) {
        await axios
          .get(this.sourceApi + "/" + this.itemId)
          .then(response => {
            this.item = response.data;
          })
          .catch(error => {
            console.error("Failed to read item, " + error);
          });
        this.mode = this.formMode.EDIT;
        this.myTitle = this.title !== "" ? this.title : "Edit";
      } else {
        this.item = { id: 0, title: "", image: "" };
        this.mode = this.formMode.ADD;
        this.myTitle = this.title !== "" ? this.title : "Add";
      }
      this.fields.forEach(field => {
        field["value"] = this.item[field.attr];
        if (this.mode == this.formMode.EDIT) {
          this.formReadOnlyFields.some(attr => attr == field.attr)
            ? (field["readonly"] = true)
            : "";
        }
      });
      if (this.mode == this.formMode.EDIT) {
        this.fields.unshift({
          attr: "_id",
          value: this.itemId,
          readonly: true
        });
      }
    },
    openDialog(event) {
      this.itemId = event;
      this.show = true;
      event !== 0
        ? this.$emit(this.appEvents.EDIT, event)
        : this.$emit(this.appEvents.ADD, event);
      this.initItem();
    },
    closeDialog() {
      this.show = false;
      this.$emit(this.appEvents.CLOSE);
    },
    onSubmit() {
      if (this.mode == this.formMode.EDIT) {
        this.update(this.item);
        this.$emit(this.appEvents.UPDATE, this.item);
      } else {
        this.save(this.item);
        this.$emit(this.appEvents.SAVE, this.item);
      }
      this.closeDialog();
    },
    onUpdate(event) {
      this.closeDialog();
    },
    save(event) {
      axios
        .post(this.sourceApi, event)
        .then(data => {
          this.$router.go(this.redirectApi);
        })
        .catch(error => {
          console.error("Error in saving Item, " + error);
        });
    },
    update(event) {
      axios
        .put(this.sourceApi + "/" + event._id, event)
        .then(data => {
          this.$router.go(this.redirectApi);
        })
        .catch(error => {
          console.error("Error in saving Item, " + error);
        });
    },
    deleteItem(event) {
      axios
        .delete(this.sourceApi + "/" + event)
        .then(data => {
          //this.$router.go(this.redirectApi);
          this.items.splice(this.items.findIndex(item => item._id == event), 1)
          this.$emit(this.appEvents.DELETE, event);
        })
        .catch(error => {
          console.error("Error in deleting Item, " + error);
        });
    }
  }
};
</script>

<style scoped></style>
