<template>
    <v-flex v-if="pickupPoints.length > 0">
        <label class="font-weight-bold">Select Pickup Point</label><br>
        <v-radio-group @change="submitPickup()" v-model="selectedPickup">
            <v-radio v-for="item in pickupPoints" :key="item.name" :value="item">
                <template slot="label">
                    <address-card :address="item" :showName="true"></address-card>
                </template>
            </v-radio>
        </v-radio-group>
    </v-flex>
</template>

<script>
import AddressCard from './AddressCard.vue'
export default {
    components: { AddressCard },
    data() {
        return {
            pickupPoints: [],
            selectedPickup: ''
        }
    },
    mounted() {
        this.pickupPoints = this.$store.getters.vendor.pickupPoints
    },
    methods: {
        submitPickup() {
            this.$emit(this.appEvents.UPDATE, this.selectedPickup)
        }
    },
}
</script>
