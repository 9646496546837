<template>
    <v-row align="center">
        <template v-if="multiple">
            <v-col cols="6" sm="4" md="3" lg="2" v-for="(file, index) in files" :key="index" class="text-center">
                <image-card :src="file.preview ? file.preview : file?.path" v-bind="$attrs" @close="removeFile(index)"
                    :showClose="true"></image-card>
            </v-col>
        </template>
        <v-col sm="auto" v-if="!multiple && files?.length > 0">
            <image-card :src="files[0]?.preview ? files[0]?.preview : files[0]?.path" v-bind="$attrs"
                @close="removeFile(0)" v-if="files[0]?.preview || files[0]?.path" :showClose="true"></image-card>
        </v-col>
        <v-col sm="auto" v-else>
            <v-card @click="$refs.fileUploadControl.click()" class="text-center upload-component-border">
                <v-card-text>
                    <input v-show="false" type="file" ref="fileUploadControl" class="btn" @change="handleFileUpload"
                        :multiple="multiple" :accept="accept" />
                    <v-icon large>mdi-image-plus</v-icon>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ImageCard from './ImageCard.vue';
import Compressor from 'compressorjs';
//import FFmpeg from '@ffmpeg/ffmpeg';

export default {
    components: { ImageCard },
    props: {
        label: {
            type: String,
            default: () => 'Upload Files'
        },
        multiple: {
            type: Boolean,
            default: () => false
        },
        accept: {
            type: String
        },
        value: {
            type: [File, Array],
            default: () => []
        },
        fileName: {
            type: String
        },
        customAttributes: {
            type: Object
        }
    },
    data() {
        return {
            files: []
        };
    },
    mounted() {
        this.files = this.value;
    },
    methods: {
        removeFile(index) {
            this.files.splice(index, 1);
        },
        async handleFileUpload(event) {
            const selectedFiles = Array.from(event.target.files);
            const processedFiles = [];

            for (let i = 0; i < selectedFiles.length; i++) {
                const originalFile = selectedFiles[i];
                const fileExtension = originalFile.name.split('.').pop(); // Get file extension
                const fileName = this.fileName || originalFile.name.split('.').slice(0, -1).join('.'); // Use `this.fileName` or the original file name
                let processedFile = originalFile;
                // Check if the file is an image based on MIME type
                if (originalFile.type.startsWith('image/')) {
                    console.log('Image file so compressing image');
                    // Compress the file
                    const compressedFile = await this.compressFile(originalFile);
                    // Create a new File object with the correct name
                    processedFile = new File([compressedFile], `${fileName}.${fileExtension}`, {
                        type: compressedFile.type,
                    });
                }
                // Add custom attributes if any
                if (this.customAttributes) {
                    Object.defineProperty(processedFile, 'customAttributes', {
                        value: this.customAttributes,
                        writable: false,
                    });
                }
                // Add preview URL
                processedFile.preview = URL.createObjectURL(processedFile);
                // Add to the processed files list
                processedFiles.push(processedFile);
            }
            this.files = processedFiles;
            this.$emit("input", this.multiple ? this.files : this.files[0]);
        },
        async compressFile(file) {
            return new Promise((resolve, reject) => {
                // Validate file input
                if (!file || !(file instanceof File)) {
                    reject(new Error('Invalid file input'));
                    return;
                }
                // Use Compressor.js to compress the file
                new Compressor(file, {
                    quality: 0.7, // Compression quality (0.6 = 60%)
                    success: (compressedFile) => {
                        resolve(compressedFile);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
            });
        },
        /* async compressVideo(inputFile) {
            const ffmpeg = await FFmpeg.createFFmpeg({ log: true });
            await ffmpeg.load();

            // Read the input video file
            ffmpeg.FS('writeFile', 'input.mp4', await fetchFile(inputFile));

            // Run the compression command (reduce bitrate to compress)
            await ffmpeg.run('-i', 'input.mp4', '-b:v', '1000k', 'output.mp4');

            // Retrieve the compressed video
            const data = ffmpeg.FS('readFile', 'output.mp4');

            // Create a Blob for the compressed video
            const compressedVideo = new Blob([data.buffer], { type: 'video/mp4' });

            return compressedVideo;
        } */
    }
};
</script>

<style scoped>
.upload-component-border {
    border: 1px dashed gray;
}
</style>
