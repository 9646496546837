<template>
    <div class="display-block">
        <v-card>
            <v-card-text>
                <v-toolbar flat>
                    <label class="text-h6">Order Details</label>
                    <v-flex text-right>
                        <v-btn small v-if="order.currentStatus == 'DELIVERED' && showInvoice" :style="theme"
                            :loading="show" :disabled="show" @click="generatePdf()">Download
                            Invoice</v-btn>
                    </v-flex>
                </v-toolbar>

                <v-flex>
                    <export-pdf @update="show = false" :show="show" :order="order" ref="exportInvoice"></export-pdf>
                </v-flex>
                <v-layout wrap>
                    <v-flex xs6>
                        <row-item label="Date" :value="order.date | dateTimeFormat"></row-item>
                    </v-flex>
                    <v-flex xs6 text-right>
                        <row-item label="Order No" :value="order.orderNo"></row-item>
                    </v-flex>
                    <v-flex xs6 sm3>
                        <router-link :to="`/app/customers/view/${order.customer._id}`">
                            <row-item label="Customer" :value="order.customer.displayName"></row-item>
                        </router-link>
                    </v-flex>
                    <v-flex xs6 sm3 text-right>
                        <row-item label="Mobile Number"
                            :value="`<a href=tel:+91${order.customer.value}>${order.customer.value}</a>`"></row-item>
                    </v-flex>
                    <v-flex xs6 sm3 :class="$vuetify.breakpoint.smAndUp ? 'text-right' : 'text-left'">
                        <row-item label="Channel"
                            :value="order.channel == 'SHOP-COUNTER' ? 'Shop' : 'Online'"></row-item>
                    </v-flex>
                    <v-flex xs6 sm3 v-if="order.delivery.date" text-right>
                        <row-item label="Delivery Date" :value="order.delivery.date"></row-item>
                    </v-flex>
                    <v-flex @click="navigateToParent()" v-if="order.parentOrder?._id" xs6 sm3>
                        <row-item label="Parent Invoice" :value="order.parentOrder.orderNo"></row-item>
                    </v-flex>
                    <v-flex text-right>
                        <row-item label="Order Type"
                            :value="order.type == 'SALE_CHALLAN' ? 'Sale Challan' : 'Normal'"></row-item>
                    </v-flex>
                    <v-flex xs12>
                        <h3 :style="themeInverted">Items</h3><br>
                    </v-flex>
                    <v-flex xs12 v-if="$vuetify.breakpoint.smAndUp">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Product</th>
                                        <th class="text-right">Quantity</th>
                                        <th class="text-right">Unit Price</th>
                                        <th class="text-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in order.items" :key="index">
                                        <td>{{ item.code }}</td>
                                        <td>{{ item.title }}
                                            <v-icon v-if="item.bType"
                                                @click="openDetail = true">mdi-information-slab-circle-outline</v-icon>
                                            <cart-product-details :showDialog="openDetail" @close="openDetail = false"
                                                :product="item" />
                                        </td>
                                        <td class="text-right">{{ item.quantity }}</td>
                                        <td class="text-right">₹{{ item.price }}</td>
                                        <td class="text-right">₹{{ item.price * item.quantity | amountFormat }}</td>
                                    </tr>
                                    <tr class="border-top">
                                        <td colspan="4" class="text-right"><b>Subtotal</b></td>
                                        <td class="text-right">₹{{ order.subTotal | amountFormat }}</td>
                                    </tr>
                                    <tr v-if="order.redeemedReward" class="border-top">
                                        <td colspan="4" class="text-right"><b>Reward</b></td>
                                        <td class="text-right">₹{{ order.redeemedReward | amountFormat }}</td>
                                    </tr>
                                    <tr v-if="order.discount.code">
                                        <td colspan="4" class="text-right"><b>Coupon</b></td>
                                        <td class="text-right"><label v-if="order.discount.code">₹{{ order.discount.code
                                                }}</label></td>
                                    </tr>
                                    <tr v-if="order.discount.amount > 0">
                                        <td colspan="4" class="text-right"><b>Discount</b></td>
                                        <td class="text-right"><label v-if="order.discount.amount > 0">₹{{
                                            order.discount.amount | amountFormat }}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right"><b>Tax</b></td>
                                        <td class="text-right">₹{{ order.taxAmount | amountFormat }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right"><b>Delivery</b></td>
                                        <td class="text-right">
                                            <label>₹{{ order.delivery.charge }}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-right"><b>Total</b></td>
                                        <td class="text-right headline">₹{{ order.total | amountFormat }}</td>
                                    </tr>
                                    <tr v-if="transactions.length > 0">
                                        <td colspan="4" class="text-right"><b>Balance</b></td>
                                        <td class="text-right headline">₹{{ order.balance == undefined ? order.total
                                            :
                                            order.balance }}/-</td>
                                    </tr>
                                </tbody><br>
                                <tbody v-if="order.trashed?.length > 0">
                                    <tr>
                                        <td>
                                            <h3 :style="themeInverted">Trashed</h3><br>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in order.trashed" :key="index">
                                        <td>{{ item.code }}</td>
                                        <td>{{ item.title }}</td>
                                        <td class="text-right">{{ item.quantity }}</td>
                                        <td class="text-right">0</td>
                                        <td class="text-right">{{ 0 * item.quantity | amountFormat }}</td>
                                    </tr>
                                </tbody><br>
                            </template>
                        </v-simple-table>
                    </v-flex>
                    <v-layout v-if="$vuetify.breakpoint.xsOnly" column>
                        <v-flex v-for="item in order.items" :key="item.code">
                            <view-order-card :item="item"></view-order-card>
                            <v-divider />
                        </v-flex>
                        <v-flex text-right>
                            <hr>
                            <row-item v-if="order.subTotal" label="Subtotal" :value="'₹' + order.subTotal"></row-item>
                            <row-item v-if="order.redeemedReward" label="Reward"
                                :value="'₹' + order.redeemedReward"></row-item>
                            <row-item v-if="order.discount.code" label="Coupon" :value="order.discount.code"></row-item>
                            <row-item v-if="order.discount.amount > 0" label="Discount"
                                :value="'₹' + order.discount.amount"></row-item>
                            <row-item v-if="order.taxAmount > 0" label="Tax" :value="'₹' + order.taxAmount"></row-item>
                            <v-divider />
                            <row-item label="Delivery charges">
                                <label>₹{{ order.delivery.charge }}</label>
                            </row-item>
                            <v-divider />
                            <hr />
                            <hr>
                            <row-item label="Total" :value="'₹' + order.total"></row-item>
                            <hr />
                            <row-item label="Balance" :value="'₹' + order.balance"></row-item>
                        </v-flex>
                    </v-layout>
                    <v-flex text-right>
                        <row-item v-if="order.delivery.type" label="Delivery Type"
                            :value="order.delivery.type.label"></row-item>
                        <row-item v-if="order.delivery.pickupPoint" label="PickUp Address"
                            :value="buildAddressPreview(order.delivery.pickupPoint)"></row-item>
                        <row-item v-if="order.delivery.address && order.delivery.type.code == 'home-delivery'"
                            label="Delivery Address"
                            :value="buildAddressPreview(order.delivery.address, true)"></row-item>
                    </v-flex>
                    <v-flex class="mt-4" v-if="order.currentStatus" xs12 text-right>
                        <v-chip>{{ order.currentStatus }}</v-chip>
                    </v-flex>
                </v-layout>
                <br>
                <v-card flat v-if="transactions.length > 0">
                    <v-card-text>
                        <h2>Transaction Details</h2>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Mode</th>
                                    <th>Narration</th>
                                    <th class="text-right">Amount</th>
                                    <th class="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, index) in transactions" :key="tr._id">
                                    <td>{{ tr.txDate | dateFormat }}</td>
                                    <td>{{ tr.mode }}</td>
                                    <td>{{ tr.narration }}</td>
                                    <td class="text-right">{{ tr.txAmount }}</td>
                                    <td class="text-right">
                                        <v-icon v-if="isAllowed('editOrder')"
                                            @click="deleteTransaction(tr._id, index)">mdi-close</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
                <v-card flat>
                    <v-card-text>
                        <v-layout>
                            <v-flex xs12 sm9></v-flex>
                            <v-flex xs12 sm3 v-if="order.statusLog.length > 0">
                                <status-timeline :orderStatusLog="order.statusLog"
                                    :currentStatus="order.currentStatus"></status-timeline>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-flex text-right>
                <order-action v-model="order" @updatedPaymentDetails="initComponent()"></order-action>
            </v-flex>
        </v-card>
    </div>
</template>

<script>
import ViewOrderCard from '@/components/ViewOrderCard'
import RowItem from '@/components/RowItem'
import CartItem from '@/components/CartItem.vue'
import appConstants from '@/utils/appConstants'
import ExportPdf from '@/components/ExportToPdf'
import PaymentDetails from '@/components/PaymentDetails.vue';
export default {
    components: {
        RowItem,
        CartItem,
        ViewOrderCard,
        PaymentDetails,
        ExportPdf
    },
    data() {
        return {
            openDetail: false,
            order: {
                id: "",
                date: "",
                customer: {
                    name: ""
                },
                items: [],
                discount: {
                    amount: 0,
                    percent: 0,
                    type: ''
                },
                tax: 0,
                taxAmount: 0,
                total: 300,
                orderNo: '',
                statusLog: [],
                delivery: {
                    date: '',
                    type: ''
                },
                paymentDetails: [],
                type: '',
            },
            headers: [{
                text: 'Product',
                value: 'name',
            },
            {
                text: 'Quantity',
                value: 'quantity',
                align: 'right'
            },
            {
                text: 'Unit Price',
                value: 'price',
                align: 'right'
            },
            {
                text: 'Amount',
                value: 'amount',
                align: 'right'
            }
            ],
            transactions: [],
            id: 0,
            show: false,
            showInvoice: true
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.order = await this.getItem(appConstants.ORDERS_API + "/" + this.id)
            if (this.order.trashed?.length > 0)
                this.sortTrashedItem()
            this.transactions = await this.getItem(`${appConstants.PAYMENT_TRANSACTION_API}/refNo/${this.order.orderNo}`)
            this.checkSuppliedItem()
        },
        sortTrashedItem() {
            for (let i = 0; i < this.order.trashed.length; i++) {
                for (let k = i + 1; k < this.order.trashed.length; k++) {
                    if (this.order.trashed[i]._id == this.order.trashed[k]._id) {
                        this.order.trashed[i].quantity += 1
                        this.order.trashed.splice(k, 1)
                    }
                }
            }
        },
        async deleteTransaction(id, index) {
            let msg = "Are you sure you want to delete this transaction?"
            await this.deleteItem(msg, appConstants.PAYMENT_TRANSACTION_API + "/" + id)
            this.transactions.splice(index, 1)
            this.initComponent()
        },
        generatePdf() {
            this.show = true
            this.$refs.exportInvoice.exportToPDF()
        },
        async checkSuppliedItem() {
            let items = await this.getItem(appConstants.SUPPLIED_ITEM_API + '/order/' + this.order._id)
            let index = items.findIndex(rec => rec.status == 'PENDING')
            if (index > -1)
                this.showInvoice = false
        },
        async navigateToParent() {
            this.$router.push(`/app/orders/view/${this.order.parentOrder._id}`)
            await this.initComponent()
        }
    }
}
</script>

<style>
.border-top {
    border-top: 1px solid black;
}
</style>
