<template>
    <div>
        <v-layout v-if="$vuetify.breakpoint.xsOnly" wrap>
            <v-flex mt-4 mx-4>
                <search-bar @input="searchProduct" :goBack="true"></search-bar>
            </v-flex>
            <v-flex v-if="categoryList.length > 0" mt-n5>
                <horizontal-scroll class="ml-4 mr-4">
                    <v-flex xs4 v-for="item in categoryList" :key="item.title">
                        <category-card-mobile :category="item"
                            @input="showSubcategory(item._id)"></category-card-mobile>
                    </v-flex>
                </horizontal-scroll>
            </v-flex>
            <v-layout wrap>
                <v-flex xs3 v-if="subCategories.length > 0">
                    <v-navigation-drawer height="700" permanent>
                        <v-list dense nav>
                            <v-list-item class="list-border mt-3" @click="getCategory(item._id, 1)"
                                v-for="(item, index) in subCategories" :key="index" link>
                                <v-list-item-content>
                                    <span v-if="item.image != null">
                                        <v-img height="24px" :src="host + item.image.path"></v-img>
                                    </span>
                                    <v-list-item-title class="menu-title">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-navigation-drawer>
                </v-flex>
                <v-flex :class="subCategories.length > 0 ? 'xs9' : 'xs12'" v-if="products.length > 0">
                    <v-flex v-for="(item, index) in products" :key="index">
                        <product-card-mobile :showElevation="true" :product="item"></product-card-mobile>
                    </v-flex>
                </v-flex>
                <v-flex :class="subCategories.length > 0 ? 'xs8 mx-4' : 'xs12'" text-center
                    v-if="products.length == 0 && subCategories.length == 0 || products.length == 0">
                    <v-alert outlined color="grey lighten-1" class="mt-4" prominent>
                        <h3>No Products Found</h3>
                    </v-alert>
                </v-flex>
            </v-layout>
            <new-cart-bar :bottomMargin="true"></new-cart-bar>
        </v-layout>
        <v-layout wrap v-if="$vuetify.breakpoint.smAndUp">
            <v-flex v-if="categoryList.length > 0" px-2 mx-8 sm2>
                <v-list width="250">
                    <v-list-group @click="showSubcategory(item._id)" v-for="item in categoryList" :key="item.title"
                        v-model="item.active" no-action>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title class="complete-text-align" v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item @click="getCategory(child._id, 1)" v-for="child in subCategories"
                            :key="child.title">
                            <v-list-item-content>
                                <v-list-item-title class="complete-text-align" v-text="child.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-flex>
            <v-flex sm9 v-if="products.length > 0">
                <bread-crumb :items="breadCrumbItems" />
                <v-card flat>
                    <v-card-text>
                        <v-layout wrap>
                            <v-flex text-center v-if="fetchingData">
                                <progress-bar :show="fetchingData" size="70"></progress-bar>
                            </v-flex>
                            <v-flex my-2 sm3 v-for="(item, index) in products" :key="index">
                                <product-card-web :item="item"></product-card-web>
                            </v-flex>
                        </v-layout>
                        <intersection-observer sentinal-name="products"
                            @onIntersection="fetchData"></intersection-observer>
                        <v-flex text-center>
                            <progress-bar :show="loading" size="30"></progress-bar>
                        </v-flex>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex :class="categoryList.length > 0 ? 'sm9' : 'sm12'" text-center
                v-if="(products.length == 0 && subCategories.length == 0) || products.length == 0">
                <v-alert prominent class="mt-5">
                    <h2>No Products Found</h2>
                </v-alert>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import IntersectionObserver from '@/components/IntersectionObserver'
import appConstants from '@/utils/appConstants'
import ProductCard from '@/components/ProductCard.vue'
import ProductDetails from '@/components/ProductDetails.vue'
import FilterItem from '@/components/FilterItem.vue'
import ProductCardWeb from '@/webstoreComponents/ProductCardWeb'
import CategoryCardMobile from '@/webstoreComponents/CategoryCardMobile'
import HorizontalScroll from '@/webstoreComponents/HorizontalScroll.vue'
import ProductCardMobile from '@/webstoreComponents/ProductCardMobile'
import SearchBar from '@/webstoreComponents/SearchBar'
import BreadCrumb from "@/webstoreComponents/BreadCrumb";
import NewCartBar from '@/webstoreComponents/NewCartBarMobile.vue';
export default {
    components: {
        ProductCard,
        FilterItem,
        ProductDetails,
        IntersectionObserver,
        ProductCardWeb,
        CategoryCardMobile,
        HorizontalScroll,
        ProductCardMobile,
        SearchBar,
        BreadCrumb,
        NewCartBar
    },
    data() {
        return {
            breadCrumbItems: [{
                text: "Home",
                disabled: false,
                href: JSON.parse(localStorage.getItem("vendor")).slug + "/store",
            },
            {
                text: "Category",
                disabled: false,
                href: "",
            },
            {
                text: " ",
                disabled: false,
                href: "",
            },
            ],
            active: true,
            searchText: '',
            products: [],
            category: undefined,
            categoryList: [],
            subCategories: [],
            vendorId: '',
            search: '',
            fetchingData: false,
            pageInfo: {
                link: {},
                meta: {}
            },
            loading: false,
            size: 20,
            productListStyle: this.$store.getters.vendor.webstoreProductsList ? this.$store.getters.vendor.webstoreProductsList : 'defaultVariants'
        }
    },
    async beforeRouteUpdate(to, from) {
        await this.searchProduct(to.params.id.replace('searchText=', ''))
    },
    created() {
        if (performance.navigation.type == 1)
            this.updateVendorSetting(this.$route.params.slug)
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            let categoryId = this.$route.params.id
            this.fetchingData = true
            this.vendorId = this.$store.getters.vendor._id
            this.categoryList = await this.getItem(appConstants.WEBSTORE_CATEGORIES + '/parentCategories')
            this.categoryList.push({ _id: '1', title: 'Bundle and Packages' })
            await this.getCategoryTitle(categoryId)
            if (categoryId == 0)
                this.showBestDeals()
            else if (categoryId.includes('searchText'))
                this.searchProduct(categoryId.replace('searchText=', ''))
            else if (categoryId) {
                await this.getCategory(categoryId, 1)
                if (this.products.length == 0)
                    this.showSubcategory(categoryId)
            }
            this.fetchingData = false
        },
        async getSubCategoryTitle(categoryId) {
            this.subCategories.forEach(rec => {
                if (categoryId == rec._id)
                    this.breadCrumbItems[2].text = rec.title
            })
        },
        async getCategoryTitle(categoryId) {
            this.categoryList.forEach(rec => {
                if (categoryId == rec._id) {
                    this.breadCrumbItems[1].text = []
                    this.breadCrumbItems[2].text = []
                    this.breadCrumbItems[1].text = rec.title
                }
            })

        },
        async getCategory(categoryId, pageNo) {
            if (this.subCategories.length == 0)
                await this.getCategoryTitle(categoryId)
            else {
                await this.getSubCategoryTitle(categoryId)
            }
            var productsMeta = await this.getItem(`${appConstants.WEBSTORE_CATEGORYWISE_PRODUCTS}/${categoryId}?variant=${this.productListStyle}&pageNo=${pageNo}`)
            this.products = pageNo > 1 ? this.products.concat(productsMeta.data) : productsMeta.data
            this.pageInfo['link'] = productsMeta.links
            this.pageInfo['meta'] = productsMeta.meta
        },
        async fetchData() {
            this.loading = true
            if (this.category?._id != '1') {
                if (this.pageInfo && this.pageInfo.link.next != undefined && this.pageInfo.link.last != 0 && this.category)
                    await this.getCategory(this.category._id, this.pageInfo.link.next)
            }
            else
                await this.getProuctBundleAndPackage(this.pageInfo.link.next)
            this.loading = false
        },
        async getProuctBundleAndPackage(pageNo) {
            let productsMeta = await this.getItem(`${appConstants.WEBSTORE_PRODUCTS}?variant=${this.productListStyle}&conditions=type=BUNDLE&pageNo=${pageNo}`)
            this.products = pageNo > 1 ? this.products.concat(productsMeta.data) : productsMeta.data
            this.pageInfo['link'] = productsMeta.links
            this.pageInfo['meta'] = productsMeta.meta
            let packages = (await this.getItem(appConstants.WEBSTORE_PACKAGES + '?pageNo=' + pageNo)).data
            packages.forEach(rec => {
                this.products.push(this.convertToProductRef(rec))
            })
            this.fetchingData = false
        },
        async showSubcategory(categoryId) {
            if (categoryId != '1') {
                this.subCategories = await this.getItem(appConstants.WEBSTORE_CATEGORIES + '/subCategory/' + categoryId)
                this.products = []
                if (this.subCategories.length == 0) {
                    this.getCategory(categoryId, 1)
                } else {
                    this.getCategory(this.subCategories[0]._id, 1)
                    await this.getCategoryTitle(categoryId)
                }
            } else {
                await this.getProuctBundleAndPackage(1)
            }
        },
        async searchProduct(searchText) {
            let productList = await this.getItem(`${appConstants.WEBSTORE_PRODUCTS}?variant=${this.productListStyle}&pageNo=1&searchText=${searchText}`)
            if (productList.data.length > 0) {
                this.products = []
                this.products = productList.data
            }
        },
        async showBestDeals() {
            this.products = await this.getItem(appConstants.WEBSTORE_PRODUCTS + '/bestDeals')
        }
    },
}
</script>

<style scoped>
.complete-text-align {
    white-space: break-spaces;
}

.no-space {
    margin-bottom: 0px !important;
}

.align-text {
    text-align: center
}

.list-border {
    border-bottom: 1px solid #d4d4d4
}

.menu-title {
    text-align: center;
    white-space: break-spaces;
}
</style>
