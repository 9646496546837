<template>
    <div class="text-left">
        <div v-if="addresses.length > 0">
            <label class="font-weight-bold">Select Delivery Address</label>
            <v-radio-group @change="submitAddress()" v-model="selectedAddress">
                <v-radio v-for="(address, index) in addresses" :key="index" :value="address">
                    <template v-slot:label>
                        <address-card :address="address" :editable="true" :deletable="true" :showName="true"
                            @edit="editAddress(address, index)" @delete="deleteAddress(index)"></address-card>
                    </template>
                </v-radio>
            </v-radio-group>
        </div>
        <alert-message v-if="msg" :error="msg"></alert-message>
        <div class="text-right">
            <v-btn outlined block :style="themeInverted" @click="$refs.addressDialog.toggleDialog(true)">Add New
                Address</v-btn>
        </div>
        <address-form :address="selectedAddress" @success="addNewAddress($event)" ref="addressDialog"
            @cancel="$refs.addressDialog.toggleDialog()"></address-form>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import AddressForm from './AddressForm.vue'
import AddressCard from './AddressCard.vue'

export default {
    props: {
        user: {
            type: [Object, String]
        },
    },
    components: {
        AddressForm,
        AddressCard
    },
    data() {
        return {
            addresses: [],
            selectedAddress: '',
            newAddress: undefined,
            saveUserAddress: false,
            loading: false,
            selectedAddressIndex: -1
        }
    },
    computed: {
        userDetails() {
            return this.user ? this.user : this.getUserProfile()
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            if (this.getUserProfile()?._id)
                await this.getItem(appConstants.WEBSTORE_CUSTOMER_ADDRESSES.replace('$id', this.getUserProfile()._id)).then(data => {
                    this.addresses = data[0].addresses
                })
        },
        submitAddress() {
            if (!this.selectedAddress) {
                this.saveUserAddress = true
                this.$emit(this.appEvents.UPDATE, this.newAddress, this.saveUserAddress)
            } else
                this.$emit(this.appEvents.UPDATE, this.selectedAddress, this.saveUserAddress)
        },
        editAddress(address, index = -1) {
            this.selectedAddress = address
            this.selectedAddressIndex = index
            this.$refs.addressDialog.toggleDialog(false)
        },
        deleteAddress(index) {
            try {
                this.addresses.splice(index, 1)
                this.putItem(`${appConstants.CUSTOMERS_API}/${this.user._id}`, { addresses: this.addresses })
            } catch (error) {
                this.handleError(error)
            }
        },
        async addNewAddress(newAddress) {
            try {
                this.loading = true
                this.selectedAddress = newAddress
                this.msg = ''
                if (this.selectedAddressIndex > -1) {
                    this.addresses.splice(this.selectedAddressIndex, 1, this.selectedAddress)
                    this.selectedAddressIndex = -1
                } else {
                    this.addresses.push(this.selectedAddress)
                }
                await this.putItem(`${appConstants.CUSTOMERS_API}/${this.user._id}`, { addresses: this.addresses })
                this.$refs.addressDialog.toggleDialog(true)
            } catch (error) {
                this.handleError(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
