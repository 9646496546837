export default {
    methods: {
        calculateTotal(order, modifyDeliveryChargesExternally = false) {
            if (!order || !order.items || order.items.length === 0) {
                return 0; // No items in the order
            }
            // Initialize variables
            const freeDeliveryAbove = this.$store.getters.vendor.freeDeliveryAbove;
            let subTotal = 0;
            // Calculate subtotal using a loop
            for (let i = 0; i < order.items.length; i++) {
                const item = order.items[i];
                subTotal += item.price * item.quantity;
            }
            // Apply subtotal to order
            order.subTotal = subTotal;
            // Apply redeemed rewards if any
            if (order.redeemedReward > 0) {
                order.subTotal = Math.max(0, order.subTotal - order.redeemedReward); // Ensure subtotal doesn't go negative
            }
            //debugger
            // Calculate delivery charge
            if (!modifyDeliveryChargesExternally) {
                if (order.delivery?.type?.code === 'home-delivery') {
                    if (freeDeliveryAbove && order.subTotal >= freeDeliveryAbove) {
                        order.delivery.charge = 0; // Free delivery for orders above the threshold
                    } else {
                        order.delivery.charge = this.$store.getters.vendor.deliveryCharge;
                    }
                } else if (!order.delivery?.type || order.delivery?.type?.code === 'pick-up-from-shop') {
                    order.delivery.charge = 0; // No delivery charge for other delivery types
                }
            }
            // Calculate tax amount
            order.taxAmount = ((order.tax || 0) * order.subTotal) / 100;
            // Calculate total
            const discount = order.discount?.amount || 0;
            order.total = order.subTotal - discount + order.taxAmount;
            // Add delivery charge to total if applicable
            if (order.delivery.charge > 0) {
                order.total += order.delivery.charge;
            }
            return order.total;
        }
    },
}