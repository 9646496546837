<template>
    <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
            <address-card :showName="true" :address="item" :editable="editable" :deletable="deletable"
                @edit="edit(index)" @delete="remove(index)" class="my-1"></address-card>
        </v-list-item>
        <div class="pa-4" v-if="editable">
            <v-btn @click="$refs.addressDialog.toggleDialog(true)" :style="theme">
                <v-icon>mdi-plus</v-icon> Add Address
            </v-btn>
        </div>
        <address-form :address="selectedItem" @success="add($event)" ref="addressDialog"
            @cancel="$refs.addressDialog.toggleDialog(true)"></address-form>
    </v-list>
</template>
<script>
import AddressCard from './AddressCard.vue'
import AddressForm from './AddressForm.vue'
export default {
    components: { AddressCard, AddressForm },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        editable: {
            type: Boolean,
            default: true
        },
        deletable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        items: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue)
            }
        }
    },
    data() {
        return {
            selectedItem: undefined,
            selectedIndex: -1
        }
    },
    methods: {
        add(item) {
            if (this.selectedIndex > -1) {
                this.items.splice(this.selectedIndex, 1, item)
            } else {
                this.items.push(item)
            }
            this.$refs.addressDialog.toggleDialog(true)
        },
        edit(index) {
            this.selectedItem = this.items[index]
            this.selectedIndex = index
            this.$refs.addressDialog.toggleDialog()
        },
        remove(index) {
            this.items.splice(index, 1)
        }
    }
}
</script>

<style scoped></style>