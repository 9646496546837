<template>
    <div>
        <v-autocomplete :items="customFields" item-text="label" v-model="selectedField" return-object>
            <template v-slot:no-data>
                <v-list-item>
                    <v-btn block>Add New</v-btn>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import appConstants from '../../utils/appConstants';

export default {
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            customFields: [],
            //selectedField: undefined
        }
    },
    computed: {
        selectedField: {
            get() {
                return this.value || undefined
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    mounted() {
        this.loadCustomFields();
    },
    methods: {
        async loadCustomFields() {
            this.customFields = await this.getItem(appConstants.CUSTOM_FIELDS_API)
        }
    },
}
</script>

<style lang="scss" scoped></style>